@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
.App {
  background-image: url('./assets/background.svg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: cover;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1em;
  color: #6a6a6a;
  display: flex;
  align-items: center;
}

.holder {
  height: 35vh;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: column;
  text-align: center;
}

.McastLogo {
  width: 8em;
}

.holder h2 {
  font-size: 1.1em;
  font-weight: 400;
}

.holder h3 {
  font-size: 1em;
  font-weight: 400;
  width: 17em;
}

.holder .appIcons {
  display: flex;
  justify-content: space-between;
  width: 20em;
}

.holder .appIcons svg {
  font-size: 4em;
}

.holder .appIcons.appLinks svg {
  font-size: 5em;
}

.holder .appIcons a {
  text-decoration: none;
}

.holder .appIcons h4 {
  font-size: 1em;
  font-weight: 700;
  color: #6a6a6a;
}

.App .holder button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
}

.App .holder button svg {
  font-size: 2.7em;
}

@media screen and (max-width: 959px) {
  .App {
    background: none;
  }

  .holder {
    width: 100vw;
    height: 80vh;
  }
}
